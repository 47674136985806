import React from 'react'
import {Helmet} from "react-helmet";
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import BuzzsproutLarge from '../components/BuzzsproutLarge'

export const PodcastPageTemplate = ({ 
  image, 
  title, 
  subtitle,
  content, 
  contentComponent ,
  podcastcover
}) => {
  const PageContent = contentComponent || Content

  return (
    <div className="content">
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`
        }}>
        <h2
          className="has-text-weight-bold is-size-1"
          style={{
            boxShadow: '0.5rem 0 0 rgba(0, 0, 0, 0.75), -0.5rem 0 0 rgba(0, 0, 0, 0.75)',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            color: 'white',
            padding: '1rem'
          }}>
          {title}
        </h2>
      </div>
      
      <div className="container">
        <div className="columns">
          <div className="column is-5 is-offset-1">
            <div className="section">
              <img src={podcastcover.image.publicURL} alt={podcastcover.alt} />
            </div>
          </div>
          <div className="column is-5">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {subtitle}
              </h2>
              <PageContent className="content" content={content} />

            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <BuzzsproutLarge />
          </div>
        </div>
      </div>


    </div>
  )
}

PodcastPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const PodcastPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout
      title={post.frontmatter.pagetitle}
      description={post.frontmatter.metadescription}
    >
      <PodcastPageTemplate
        image={post.frontmatter.image}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        content={post.html}
        podcastcover={post.frontmatter.podcastcover}
      />
    </Layout>
  )
}

PodcastPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PodcastPage

export const podcastPageQuery = graphql`
  query PodcastPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        pagetitle
        metadescription
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        podcastcover {
          image {
            childImageSharp {
              fluid(maxWidth: 240, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
        }
      }
    }
  }
`
